// App.js

import React from 'react'

// react admin
import { Admin, Resource } from 'react-admin'

//Icons
import PersonIcon from '@mui/icons-material/Person'
import QuizIcon from '@mui/icons-material/Quiz'

// resources & common imports
import {
  NotificationList,
  NotificationShow,
  NotificationEdit,
  NotificationCreate,
  NotificationIcon,
  CallList,
  CallShow,
  CallEdit,
  CallCreate,
  CallIcon,
  SessionList,
  SessionShow,
  SessionEdit,
  SessionCreate,
  SessionIcon,
  //ConferenceList, ConferenceShow, ConferenceEdit, ConferenceCreate, ConferenceIcon,
  TranscriptionList,
  TranscriptionShow,
  TranscriptionEdit,
  TranscriptionCreate,
  TranscriptionIcon,
  authProvider,
  Login,
  Layout,
  i18nProvider,
  history,
} from 'kevo-react-admin'

import Dashboard from './Dashboards/Dashboard.js'

import { UserList, UserShow, UserEdit, UserCreate } from './resources/users.js'

import { UserAnswerList, UserAnswerShow } from './resources/useranswers.js'

import interedTheme from './themes/interedTheme.js'

const App = (props) => {
  return (
    props.dataProvider && (
      <Admin
        disableTelemetry
        style={{ flexGrow: 1 }}
        history={history}
        title={`${process.env.REACT_APP_SITE_NAME} - Administrador`}
        i18nProvider={i18nProvider}
        loginPage={Login}
        //layout={Layout}
        dashboard={Dashboard}
        authProvider={authProvider}
        dataProvider={props.dataProvider}
        theme={interedTheme}
      >
        {(permissions) => [
          /*['ADMIN', 'MANAGER'].includes(permissions) ? (
            <Resource
              name="users"
              options={{ label: 'Usuarios' }}
              list={UserList}
              show={UserShow}
              edit={UserEdit}
              create={UserCreate}
              icon={PersonIcon}
            />
          ) : (
            <Resource
              name="users"
              options={{ label: 'Usuarios' }}
              list={null}
              show={null}
              edit={null}
              create={null}
              icon={PersonIcon}
            />
          ),*/
          ['ADMIN', 'MANAGER'].includes(permissions) ? (
            <Resource
              name="useranswers"
              options={{ label: 'Tests' }}
              list={UserAnswerList}
              show={UserAnswerShow}
              edit={UserAnswerShow}
              create={null}
              icon={QuizIcon}
            />
          ) : (
            <Resource
              name="users"
              options={{ label: 'Usuarios' }}
              list={UserAnswerList}
              show={UserAnswerShow}
              edit={UserAnswerShow}
              create={null}
              icon={QuizIcon}
            />
          ),
        ]}
      </Admin>
    )
  )
}

export default App

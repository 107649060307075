// Dashboard.js

import React from 'react'

// material components
import Card from '@mui/material/Card/index.js'
import CardContent from '@mui/material/CardContent/index.js'
import CardHeader from '@mui/material/CardHeader/index.js'

// material icons
import QuizIcon from '@mui/icons-material/Quiz'

// custom components
import { useGetList, Loading, Error } from 'react-admin'
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js/auto'
import { Pie } from 'react-chartjs-2'
import { Bar } from 'react-chartjs-2'

ChartJS.register(ArcElement, Tooltip, Legend)
ChartJS.register(ArcElement, Tooltip, Legend)

if (process.env.REACT_APP_SITE_NAME === undefined) {
  console.error('env var "REACT_APP_SITE_NAME" is not defined')
}
if (process.env.REACT_APP_SITE_SUBTITLE === undefined) {
  console.error('env var "REACT_APP_SITE_SUBTITLE" is not defined')
}

const Stats = ({ permissions, ...props }) => {
  const { data, isLoading, error } = useGetList('useranswers', {
    pagination: { page: 1, perPage: 100 },
    sort: { field: 'id', order: 'DESC' },
  })

  if (isLoading) {
    return <Loading />
  }
  if (error) {
    return <p>ERROR</p>
  }

  //test
  let testSinTerminar = 0
  let relacionSana = 0
  let relacionAlgoToxica = 0
  let relacionPeligrosa = 0
  let relacionMuyPeligrosa = 0
  let relacionGrave = 0

  //lang
  let castellano = 0
  let euskera = 0

  //Set testdata
  for (let useranswer of data) {
    //Set test data
    if (useranswer.result === 0) {
      testSinTerminar++
    } else if (useranswer.result === 1) {
      relacionSana++
    } else if (useranswer.result === 2) {
      relacionAlgoToxica++
    } else if (useranswer.result === 3) {
      relacionPeligrosa++
    } else if (useranswer.result === 4) {
      relacionMuyPeligrosa++
    } else {
      relacionGrave++
    }

    //set lang data
    if (useranswer.lang === 'Castellano') {
      castellano++
    } else {
      euskera++
    }
  }

  //Sacar porcentajes
  let testSinTerminarP = (testSinTerminar / data.length) * 100
  let relacionSanaP = (relacionSana / data.length) * 100
  let relacionAlgoToxicaP = (relacionAlgoToxica / data.length) * 100
  let relacionPeligrosaP = (relacionPeligrosa / data.length) * 100
  let relacionMuyPeligrosaP = (relacionMuyPeligrosa / data.length) * 100
  let relacionGraveP = (relacionGrave / data.length) * 100

  const pieOptions = {
    responsive: true,
    plugins: {
      legend: true,
      title: {
        display: true,
        text: 'Porcentaje de tests en cada categoría de peligro',
      },
    },
  }

  let testData = {
    labels: [
      'Test sin terminar',
      'Relación Sana',
      'Situaciones tóxicas',
      'Violencia psicológica',
      'Relación agresiva',
      'Violencia Grave',
    ],
    datasets: [
      {
        label: '% de tests',
        data: [
          testSinTerminarP,
          relacionSanaP,
          relacionAlgoToxicaP,
          relacionPeligrosaP,
          relacionMuyPeligrosaP,
          relacionGraveP,
        ],
        backgroundColor: ['white', 'lightgreen', 'yellow', '#ff5050', 'red', '#a40202'],
        borderColor: ['grey', 'lightgreen', 'yellow', '#ff5050', 'red', '#a40202'],
        borderWidth: 1,
      },
    ],
  }

  const barOptionsLang = {
    indexAxis: 'y',
    elements: {
      bar: {
        borderWidth: 2,
      },
    },
    responsive: true,
    plugins: {
      position: 'top',
      legend: false,
      title: {
        display: true,
        text: 'Número de hablantes en euskera y castellano',
      },
    },
  }

  let langData = {
    labels: ['Castellano', 'Euskera'],
    datasets: [
      {
        data: [castellano, euskera],
        borderColor: ['#4d9d2a', '#883099'],
        backgroundColor: ['#4d9d2a', '#883099'],
      },
    ],
  }

  const barOptionsTest = {
    indexAxis: 'y',
    elements: {
      bar: {
        borderWidth: 2,
      },
    },
    responsive: true,
    plugins: {
      position: 'top',
      legend: false,
      title: {
        display: true,
        text: 'Numero de tests en cada categoría de peligro',
      },
    },
  }

  let testDataBar = {
    labels: [
      'Test sin terminar',
      'Relación Sana',
      'Situaciones tóxicas',
      'Violencia psicológica',
      'Relación agresiva',
      'Violencia Grave',
    ],
    datasets: [
      {
        label: '# de tests',
        data: [
          testSinTerminar,
          relacionSana,
          relacionAlgoToxica,
          relacionPeligrosa,
          relacionMuyPeligrosa,
          relacionGrave,
        ],
        backgroundColor: ['white', 'lightgreen', 'yellow', '#ff5050', 'red', '#a40202'],
        borderColor: ['grey', 'lightgreen', 'yellow', '#ff5050', 'red', '#a40202'],
      },
    ],
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <h3>Total de tests: {data.length} </h3>
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
        <Bar
          style={{ maxHeight: '300px', maxWidth: '350px', margin: '50px' }}
          options={barOptionsTest}
          data={testDataBar}
        />
        <Bar
          style={{ maxHeight: '200px', maxWidth: '250px', margin: '50px' }}
          options={barOptionsLang}
          data={langData}
        />
      </div>
      <Pie style={{ maxHeight: '350px', maxWidth: '400px', margin: '50px' }} options={pieOptions} data={testData} />
    </div>
  )
}

const Dashboard = ({ permissions, ...props }) => {
  //console.log(props)
  return (
    <div style={{ dispay: 'flex', marginTop: '24px !important', alignItems: 'center' }}>
      <Card>
        <CardHeader
          title={`Bienvenido a ${process.env.REACT_APP_SITE_NAME}`}
          subheader={process.env.REACT_APP_SITE_SUBTITLE}
        />
        <CardContent>
          <p style={{ fontFamily: '"Roboto","Helvetica","Arial",sans-serif' }}>
            Para ver los tests que se han realizado haz click aquí:
            <a href="/useranswers" style={{ textDecoration: 'none', color: 'grey' }}>
              <QuizIcon style={{ marginLeft: '5px', marginBottom: '-6px' }} />
            </a>
          </p>

          <Stats />
        </CardContent>
      </Card>
    </div>
  )
}

export default Dashboard

// users.js

import { useState } from 'react'

// react admin
import {
  useRecordContext,
  List,
  PasswordInput,
  FunctionField,
  NumberField,
  SelectField,
  Pagination,
  Datagrid,
  TextField,
  EmailField,
  DateField,
  Filter,
  Create,
  TopToolbar,
  CreateButton,
  DeleteButton,
  SelectInput,
  TextInput,
  BooleanInput,
  Edit,
  SimpleForm,
  BooleanField,
  Show,
  SimpleShowLayout,
  Loading,
  BulkDeleteButton,
  usePermissions,
  ReferenceInput,
} from 'react-admin' //useDelete

// mui components
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

import { ContainerBox, LeftBox, RightBox, MarginLabeledLeft, MarginLabeledRight, showStars } from 'kevo-react-admin'

/*
create type usertypechoices as enum ('client','interpreter','admin');

create table users(
id serial not null primary key,
uuid uuid not null unique default gen_random_uuid(),
type usertypechoices not null default 'client',
active boolean not null default false,
lastlogin timestamp,
username varchar(255) not null unique,
password varchar(255) not null,
name varchar(255) not null,
email varchar(255) not null,
created timestamp not null default now(),
updated timestamp
);
*/

//const resource = 'users'
const resourceName = 'usuario'
const resourceNamePlural = 'usuarios'
const masculine = true
const mainField = 'username'

const ListTitle = () => {
  return <span>{`${resourceNamePlural.charAt(0).toUpperCase()}${resourceNamePlural.slice(1)}`}</span>
}

const ListFilters = ({ permissions }) => (
  <Filter>
    <TextInput label={`Buscar ${masculine ? 'un' : 'una'} ${resourceName}...`} source={`${mainField}`} alwaysOn />
    <TextInput label="Buscar un email..." source="email" alwaysOn />
    <SelectInput
      style={{ marginBottom: '0px' }}
      label="Filtrar tipo..."
      source="type"
      alwaysOn
      choices={
        permissions === 'ADMIN'
          ? [
              { id: 'CLIENT', name: 'Cliente' },
              { id: 'CONSULTOR', name: 'Consultor' },
              { id: 'MANAGER', name: 'Gestor' },
              { id: 'ADMIN', name: 'Administrador' },
            ]
          : permissions === 'MANAGER'
          ? [
              { id: 'CLIENT', name: 'Cliente' },
              { id: 'CONSULTOR', name: 'Consultor' },
              { id: 'MANAGER', name: 'Gestor' },
            ]
          : [
              { id: 'CLIENT', name: 'Cliente' },
              { id: 'CONSULTOR', name: 'Consultor' },
            ]
      }
    />
  </Filter>
)

const ListPagination = () => {
  return <Pagination rowsPerPageOptions={[10, 25, 50, 100]} />
}

const ListActions = () => (
  <TopToolbar>
    <DeleteButton />
    <CreateButton />
  </TopToolbar>
)

export const UserList = () => {
  const { isLoading, permissions } = usePermissions()
  if (isLoading) {
    return <Loading />
  }
  return (
    <List
      title={<ListTitle />}
      filters={<ListFilters permissions={permissions} />}
      /*filterDefaultValues={filters}*/
      pagination={<ListPagination />}
      actions={<ListActions />}
      bulkActionButtons={<BulkDeleteButton undoable="false" />}
      sort={{ field: 'created' }}
      perPage={50}
    >
      <Datagrid rowClick="edit">
        {['ADMIN'].includes(permissions) && <NumberField source="id" label="ID" textAlign="left" />}
        <SelectField
          source="type"
          label="Tipo"
          choices={[
            { id: 'CLIENT', name: 'Cliente' },
            { id: 'CONSULTOR', name: 'Consultor' },
            { id: 'MANAGER', name: 'Gestor' },
            { id: 'ADMIN', name: 'Administrador' },
          ]}
        />
        <BooleanField source="active" label="Activo" textAlign="left" />
        {/*<DateField source="lastlogin" label="Último login" textAlign="left" emptyText="Nunca" locales="es" showTime={true} options={{year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric', timeZone: 'Europe/Madrid'}} />*/}
        <TextField source="username" label="Usuario" textAlign="left" />
        <TextField source="name" label="Nombre" textAlign="left" />
        {/*<FunctionField label="Contraseña" render={ShowStars} textAlign="left" />*/}
        <DateField
          sortable={false}
          source="created"
          label="Creado"
          textAlign="left"
          locales="es"
          showTime={true}
          options={{
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            timeZone: 'Europe/Madrid',
          }}
        />
        <DateField
          sortable={false}
          source="updated"
          label="Actualizado"
          textAlign="left"
          emptyText="Nunca"
          locales="es"
          showTime={true}
          options={{
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            timeZone: 'Europe/Madrid',
          }}
        />
      </Datagrid>
    </List>
  )
}

const ShowTitle = () => {
  const record = useRecordContext()
  return (
    <span>{`${resourceName.charAt(0).toUpperCase()}${resourceName.slice(1)} ${record ? record[mainField] : ''}`}</span>
  )
}

export const UserShow = (props) => {
  return (
    <Show title={<ShowTitle />}>
      <SimpleShowLayout>
        <SelectField
          source="type"
          label="Tipo"
          choices={[
            { id: 'CLIENT', name: 'Cliente' },
            { id: 'CONSULTOR', name: 'Consultor' },
            { id: 'MANAGER', name: 'Gestor' },
            { id: 'ADMIN', name: 'Administrador' },
          ]}
        />
        <BooleanField source="active" label="Activo" textAlign="left" />
        <DateField
          sortable={false}
          source="lastlogin"
          label="Último login"
          textAlign="left"
          emptyText="Nunca"
          locales="es"
          showTime={true}
          options={{
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            timeZone: 'Europe/Madrid',
          }}
        />
        <TextField source="username" label="Usuario" textAlign="left" />
        <FunctionField label="Contraseña" render={showStars} textAlign="left" />
        <TextField source="name" label="Nombre" textAlign="left" />
        <TextField source="phone" label="Teléfono" textAlign="left" />
        <EmailField source="email" label="Email" textAlign="left" />
        <DateField
          source="created"
          label="Creado"
          textAlign="left"
          locales="es"
          showTime={true}
          options={{
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            timeZone: 'Europe/Madrid',
          }}
        />
        <DateField
          source="updated"
          label="Actualizado"
          textAlign="left"
          emptyText="Nunca"
          locales="es"
          showTime={true}
          options={{
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            timeZone: 'Europe/Madrid',
          }}
        />
      </SimpleShowLayout>
    </Show>
  )
}

const EditTitle = (props) => {
  const record = useRecordContext()
  return (
    <span>
      Editar {`${resourceName.charAt(0).toUpperCase()}${resourceName.slice(1)} ${record ? record[mainField] : ''}`}
    </span>
  )
}

export const UserEdit = () => {
  const { isLoading, permissions } = usePermissions()
  //console.log(classes)
  if (isLoading) {
    return <Loading />
  }
  return (
    <Edit title={<EditTitle />} actions={null}>
      <SimpleForm>
        <ContainerBox style={{ maxWidth: '90vw' }}>
          <LeftBox style={{ maxWidth: '45vw' }}>
            <MarginLabeledLeft label="Tipo">
              <SelectField
                source="type"
                choices={[
                  { id: 'CLIENT', name: 'Cliente' },
                  { id: 'CONSULTOR', name: 'Consultor' },
                  { id: 'MANAGER', name: 'Gestor' },
                  { id: 'ADMIN', name: 'Administrador' },
                ]}
              />
            </MarginLabeledLeft>
          </LeftBox>
          <RightBox style={{ maxWidth: '45vw' }}>
            <BooleanInput
              source="active"
              label="Activo"
              textAlign="left"
              helperText="Si no está activo no podrá iniciar sesión"
            />
            <MarginLabeledRight label="Creado">
              <DateField
                sortable={false}
                source="created"
                textAlign="left"
                locales="es"
                showTime={false}
                options={{
                  year: 'numeric',
                  month: 'numeric',
                  day: 'numeric',
                  hour: 'numeric',
                  minute: 'numeric',
                  timeZone: 'Europe/Madrid',
                }}
                sx={{ display: 'inline-flex', marginInlineEnd: '2rem', float: 'right' }}
              />
            </MarginLabeledRight>
            <MarginLabeledRight label="Actualizado">
              <DateField
                sortable={false}
                source="updated"
                emptyText="Nunca"
                textAlign="left"
                locales="es"
                showTime={true}
                options={{
                  year: 'numeric',
                  month: 'numeric',
                  day: 'numeric',
                  hour: 'numeric',
                  minute: 'numeric',
                  timeZone: 'Europe/Madrid',
                }}
                sx={{ display: 'inline-flex', marginInlineEnd: '2rem', float: 'right' }}
              />
            </MarginLabeledRight>
            <MarginLabeledRight label="Último login">
              <DateField
                sortable={false}
                source="lastlogin"
                emptyText="Nunca"
                textAlign="left"
                locales="es"
                showTime={true}
                options={{
                  year: 'numeric',
                  month: 'numeric',
                  day: 'numeric',
                  hour: 'numeric',
                  minute: 'numeric',
                  timeZone: 'Europe/Madrid',
                }}
                sx={{ display: 'inline-flex', marginInlineEnd: '2rem', float: 'right' }}
              />
            </MarginLabeledRight>
          </RightBox>
        </ContainerBox>
        <ContainerBox>
          <LeftBox>
            <TextInput
              source="username"
              label="Usuario (no se puede editar una vez creado)"
              textAlign="left"
              fullWidth={true}
              disabled
            />
          </LeftBox>
          <RightBox>
            <PasswordInput
              source="password"
              label="Contraseña"
              textAlign="left"
              fullWidth={true}
              inputProps={{ autoComplete: 'current-password' }}
              initiallyVisible={false}
              helperText="La contraseña se encriptará una vez cambiada"
            />
          </RightBox>
        </ContainerBox>
        <TextInput source="name" label="Nombre" textAlign="left" fullWidth={true} />
        <TextInput source="phone" label="Teléfono" textAlign="left" fullWidth={true} />
        <TextInput source="email" label="Email" textAlign="left" fullWidth={true} />
      </SimpleForm>
    </Edit>
  )
}

const CreateTitle = () => {
  return (
    <span>
      Crear {masculine ? 'nuevo' : 'nueva'} {`${resourceName.charAt(0).toUpperCase()}${resourceName.slice(1)}`}
    </span>
  )
}

// source: https://stackoverflow.com/questions/9628879/javascript-regex-username-validation
const isUsernameValid = (username) => {
  /* 
    Usernames can only have: 
    - Lowercase Letters (a-z) 
    - Numbers (0-9)
    - Underscores (_)
  */
  const res = /^[ña-zA-Z0-9_]+$/.exec(username)
  const valid = !!res
  return valid
}

const validateUserCreation = (values) => {
  //console.log(values)
  const errors = {}
  if (!values.type) {
    errors.type = 'Es necesario indicar el tipo de usuario'
  }
  if (values.active === undefined) {
    errors.active = 'Es necesario indicar si el usuario está activo o no'
  }
  if (!values.username) {
    errors.username = 'Es necesario introducir un nombre de usuario'
  } else if (!isUsernameValid(values.username)) {
    errors.username =
      'El nombre de usuario solamente puede tener letras mayúsculas, minúsculas, números y barras bajas (_), no puede contener acentos y espacios (entre otras cosas).'
  }
  if (!strongRegex.test(values.password)) {
    errors.password = 'La contraseña no parece cumplir los requisitos mínimos'
  }
  if (!values.name) {
    errors.name = 'Es necesario introducir un nombre'
  }
  if (!values.email) {
    errors.email = 'Es necesario introducir un email'
  }
  return errors
}

// password regexes
//const strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\\$%\\^&\\*])(?=.{8,})");
const strongRegex = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})')
// the password parts
const lowercaseRegex = new RegExp('^(?=.*[a-z])')
const uppercaseRegex = new RegExp('^(?=.*[A-Z])')
const numericRegex = new RegExp('^(?=.*[0-9])')
const specialRegex = new RegExp('^(?=.*[!@#$%^&*])')
const lengthRegex = new RegExp('^(?=.{8,})')

export const UserCreate = (props) => {
  const [lowercase, setLowercase] = useState(false)
  const [uppercase, setUppercase] = useState(false)
  const [numeric, setNumeric] = useState(false)
  const [special, setSpecial] = useState(false)
  const [length, setLength] = useState(false)
  // load perms
  const { isLoading, permissions } = usePermissions()
  if (isLoading) {
    return <Loading />
  }
  const checkPassword = (event) => {
    // source: https://www.thepolyglotdeveloper.com/2015/05/use-regex-to-test-password-strength-in-javascript/
    console.log(event.target.value)
    setLowercase(lowercaseRegex.test(event.target.value))
    setUppercase(uppercaseRegex.test(event.target.value))
    setNumeric(numericRegex.test(event.target.value))
    setSpecial(specialRegex.test(event.target.value))
    setLength(lengthRegex.test(event.target.value))
  }
  return (
    <Create title={<CreateTitle />}>
      <SimpleForm validate={validateUserCreation}>
        {
          //Only admins can create other admins
          ['ADMIN'].includes(permissions) ? (
            <SelectInput
              source="type"
              label="Tipo"
              helperText="Tipo de usuario (nota: no se puede cambiar una vez creado)"
              fullWidth={true}
              choices={[
                { id: 'CLIENT', name: 'Cliente' },
                { id: 'CONSULTOR', name: 'Consultor' },
                { id: 'MANAGER', name: 'Gestor' },
                { id: 'ADMIN', name: 'Administrador' },
              ]}
            />
          ) : ['MANAGER'].includes(permissions) ? (
            <SelectInput
              source="type"
              label="Tipo"
              helperText="Tipo de usuario (nota: no se puede cambiar una vez creado)"
              fullWidth={true}
              choices={[
                { id: 'CLIENT', name: 'Cliente' },
                { id: 'CONSULTOR', name: 'Consultor' },
              ]}
            />
          ) : (
            <SelectInput
              source="type"
              label="Tipo"
              helperText="Tipo de usuario (nota: no se puede cambiar una vez creado)"
              fullWidth={true}
              choices={[{ id: 'CLIENT', name: 'Cliente' }]}
            />
          )
        }
        <BooleanInput
          source="active"
          initialValue={false}
          label="Activo"
          textAlign="left"
          helperText="Si no está activo no podrá iniciar sesión"
          fullWidth={true}
        />
        <TextInput
          source="username"
          label="Usuario (no se puede editar una vez creado, tiene que ser único)"
          textAlign="left"
          helperText="Tiene que ser único"
          fullWidth={true}
        />
        <PasswordInput
          source="password"
          label="Contraseña"
          textAlign="left"
          fullWidth={true}
          inputProps={{ autoComplete: 'current-password' }}
          helperText="La contraseña se encriptará una vez introducida"
          onChange={checkPassword}
        />
        <Box style={{ paddingBottom: '8px' }}>
          <Typography variant="caption" gutterBottom style={{ marginLeft: '14px' }}>
            Make sure that the password meets the following criteria and contains:
            <ul>
              <li style={{ color: lowercase ? 'green' : 'red' }}>at least 1 lowercase alphabetical character</li>
              <li style={{ color: uppercase ? 'green' : 'red' }}>at least 1 uppercase alphabetical character</li>
              <li style={{ color: numeric ? 'green' : 'red' }}>at least 1 numeric character</li>
              <li style={{ color: special ? 'green' : 'red' }}>at least one special character</li>
              <li style={{ color: length ? 'green' : 'red' }}>eight characters or longer</li>
            </ul>
          </Typography>
        </Box>
        <TextInput source="name" label="Nombre" textAlign="left" fullWidth={true} />
        <TextInput source="phone" label="Teléfono" textAlign="left" fullWidth={true} />
        <TextInput source="email" label="Email" textAlign="left" fullWidth={true} />
      </SimpleForm>
    </Create>
  )
}

// mainTheme.js

// mui
import { createTheme } from '@mui/material/styles/index.js'

// env warnings
if (process.env.REACT_APP_THEME_COLOR === undefined) {
  console.error('env var "REACT_APP_THEME_COLOR" is not defined')
}
if (process.env.REACT_APP_FONT_FAMILY === undefined) {
  console.error('env var "REACT_APP_FONT_FAMILY" is not defined')
}
if (process.env.REACT_APP_FONT_COLOR === undefined) {
  console.error('env var "REACT_APP_FONT_COLOR" is not defined')
}

const interedTheme = createTheme({
  palette: {
    // see https://material-ui.com/customization/color/#official-color-tool
    // color tool https://material.io/resources/color/#!/
    primary: {
      //light: '#5fd2ff',
      main: process.env.REACT_APP_THEME_COLOR,
      //dark: '#0072a0',
      contrastText: '#fff',
    },
    secondary: {
      //light: '#508dd0',
      main: process.env.REACT_APP_THEME_COLOR,
      //dark: '#003770',
      contrastText: '#fff',
    },
    /*contrastThreshold: 3,
      tonalOffset: 0.2,*/
  },
  typography: {
    // Use the system font instead of the default Roboto font.
    fontFamily: [process.env.REACT_APP_FONT_FAMILY].join(','),
    color: process.env.REACT_APP_FONT_COLOR,
  },
  components: {
    MuiAvatar: {
      styleOverrides: {
        root: {
          backgroundRepeat: 'no-repeat',
        },
      },
    },
    RaLabeled: {
      styleOverrides: {
        root: {
          fontSize: '19px',
        },
      },
    },
  },
})

// checkout for customization of elements in the theme:
// https://mui.com/material-ui/customization/theme-components/#global-style-overrides

export default interedTheme

// users.js

import { useState } from 'react'

// react admin
import {
  useRecordContext,
  List,
  PasswordInput,
  FunctionField,
  NumberField,
  SelectField,
  Pagination,
  Datagrid,
  TextField,
  EmailField,
  DateField,
  Filter,
  Create,
  TopToolbar,
  CreateButton,
  DeleteButton,
  SelectInput,
  TextInput,
  BooleanInput,
  Edit,
  SimpleForm,
  BooleanField,
  Show,
  SimpleShowLayout,
  Loading,
  BulkDeleteButton,
  usePermissions,
  ReferenceInput,
  useGetList,
  useGetOne,
} from 'react-admin' //useDelete

// mui components
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

import { ContainerBox, LeftBox, RightBox, MarginLabeledLeft, MarginLabeledRight, showStars } from 'kevo-react-admin'

const questions = [
  'Cuando piensas en tu relación de pareja, ¿te sientes feliz, tranquila y plena? ',
  '¿Consideras que el reparto del trabajo (las tareas) del hogar y de los hijos o hijas (si los tenéis) es igualitario? ',
  'Cuando piensas en tu relación de pareja, ¿sientes que quieres que se acabe y que te sentirías aliviada o feliz si ya no estuvieras "atrapada" en esa relación? ',
  '¿Tu percepción es que los primeros meses o los primeros años de tu relación eran felices, de "cuento de hadas", idílicos, llenos de romanticismo y que, con el tiempo, la relación o tu pareja han cambiado radicalmente?',
  'Cuando piensas en tu relación de pareja ¿Sientes que tus decisiones y opiniones se tienen en consideración en igualdad de condiciones respecto a las de tu pareja?',
  '¿Normalmente en tu relación de pareja te sientes estresada, irritada, angustiada con ansiedad y/o con pánico?',
  '¿Alguna vez tu familia o tus amistades te han hecho notar que no te ven feliz o incluso te han recomendado dejar la relación?',
  '¿Alguna vez has roto esa relación porque pensabas que no es buena para ti y después has vuelto?',
  ' Alguna vez has querido terminar tu relación de pareja, pero has seguido por...  (elige el motivo principal)',
  '¿Tienes la sensación de que tu pareja se enfada o monta una bronca por cosas sin importancia o nimiedades?',
  '¿Vives con el miedo a que se enfade o monte en cólera por cualquier cosa y cambias tus costumbres o actitudes para "que no se enfade"?',
  '¿En alguna ocasión te ha humillado en público (por ejemplo, insultándote, haciéndote de menos, quitando importancia a lo que haces o a tu opinión etc.)?',
  '¿En alguna ocasión te ha humillado delante de tus/vuestros hijos o hijas  o en privado a solas (por ejemplo, insultándote, haciéndote de menos, quitando importancia a lo que haces o a tu opinión etc.)?',
  '¿Consideras que tu pareja desvaloriza tu trabajo o no te toma en serio?',
  'Tu pareja te dice frases como "eres mía" o "si te veo con otro me vuelvo loco" o "tengo celos porque te quiero/porque me importas". ¿Justifica sus celos de alguna manera?',
  '¿Tiene ataques de celos por compañeros/as de trabajo, amistades, exparejas y otras personas de tu entorno y te sugiere que estás flirteando o intentando ligar?',
  '¿Consideras que tienes una relación tóxica? Entendiendo una relación tóxica como aquella que te genera daño, malestar o sufrimiento habitualmente, que es destructiva, pero de la que es difícil salir.',
  '¿Tu pareja te hace sentir inferior, tonta o inútil o que todo lo haces mal?',
  '¿Tu pareja te hace sentir culpable, "tú tienes la culpa de todo"?',
  '¿Crítica y descalifica a tu familia y/o a tus amistades?',
  '¿Te ha prohibido hablar, tratar o relacionarte con vecinas, vecinos o madres y padres del colegio de tus hijas/hijos, compañeras o compañeros de trabajo? ',
  '¿Te ha prohibido hablar, tratar o relacionarte con alguien de tu familia o de tus amistades?',
  '¿Consideras que has cambiado o dejado de hacer actividades que te gustaban para evitar que tu pareja se enfade o porque te lo ha sugerido, pedido o exigido?',
  '¿Consideras que tu pareja controla tu dinero, te pide explicaciones sobre los gastos que realizas, o que se aprovecha económicamente de ti?',
  '¿Consideras que tu pareja controla tu forma de vestir o de maquillarte: hace comentarios despectivos para que cambies tu ropa antes de salir a la calle o de asistir a algún evento porque dice que "vas provocando"?',
  '¿Consideras que tu pareja te da órdenes, te dice cómo tienes que hacer las cosas o decide por ti?',
  '¿Crees que tu pareja controla tu móvil, espía tu teléfono o tus redes sociales y/o correo electrónico?',
  '¿Alguna vez te ha chantajeado o amenazado si no le proporcionas tus contraseñas personales para acceder a tu móvil, correo o redes sociales?',
  '¿Tu pareja te pide explicaciones y/o se enfada por los mensajes de WhatsApp, telegram o móvil, o llamadas de teléfono, o emails o mensajes o likes en tus redes sociales?',
  '¿Tu pareja ha roto o tirado a la basura objetos que tenían un valor sentimental o que eran importantes para ti?',
  '¿Tu pareja ha mostrado alguna vez actitudes agresivas hacia tu mascota si la tienes (perro, gato etc.)?',
  '¿Alguna vez tu pareja ha aplicado la Ley de hielo: te ignora, se muestra insensible o te deja de hablar durante horas, días o semanas como "castigo" a algo que él cree que has hecho, ¿o sin razón aparente?',
  '¿Tu pareja decide los planes, lo que hacéis en vuestro tiempo libre, como pareja o como familia y tienes la sensación de que tus opiniones o preferencias no se tienen en cuenta?',
  '¿Has sentido miedo de su mirada o gestos?',
  '¿Tu pareja te grita?',
  '¿Tu pareja te insulta?',
  '¿Tu pareja hace comentarios despectivos sobre tu cuerpo, tu talla, tu cara, alguna característica física, etc.?',
  '¿Tu pareja te amenaza habitualmente con dejar la relación?',
  '¿Tu pareja te ha amenazado alguna vez con echarte de casa o te ha impedido acceder a tu domicilio de alguna manera?',
  '¿Tu pareja amenaza a tus/vuestros hijas/hijos?',
  '¿Tu pareja te amenaza verbalmente con hacerte daño?',
  '¿Tu pareja te amenaza verbalmente con matarte?',
  '¿Tu pareja te ha amenazado con un arma, un cuchillo, navaja o un objeto?',
  '¿Consideras que tu pareja es violento?',
  '¿Te has sentido vulnerable o indefensa frente a tu pareja? ¿Has sentido miedo?',
  '¿Tu pareja ha pegado un puñetazo o patada a algún elemento en tu presencia (una mesa, una silla, pared o algún objeto)?',
  '¿Tu pareja te ha empujado?',
  '¿Tu pareja te ha dado una torta o bofetón?',
  '¿Tu pareja te ha pegado un puñetazo/varios puñetazos; o te ha lesionado o fracturado alguna parte de tu cuerpo o te ha hecho un corte o algo similar?',
  'Después de una discusión, bronca o agresión, ¿tu pareja te pide perdón y se muestra arrepentido y/o te dice frases como "es la última vez" o "voy a cambiar"?',
  'Después de una discusión, bronca o agresión, ¿tu pareja te pide perdón y le perdonas?',
  '¿Consideras que tu pareja te obliga o te fuerza a mantener relaciones sexuales incluso cuando tú le dices que no te apetece o no quieres y/o te obliga a hacer ciertas prácticas que tú no deseas?',
  '¿Tu pareja te amenaza con dejarte o serte infiel si no mantienes relaciones sexuales con él y/o te sientes coaccionada a hacerlo?',
  '¿Tu pareja ha utilizado la fuerza física y/o verbal para mantener relaciones sexuales contra tu voluntad a pesar de tu negativa?',
  '¿Una vez iniciada la relación sexual o los preliminares, le pides que pare por la razón que sea (por dolor, porque no te apetece, por cualquier razón) y tu pareja  NO para?',
  '¿Consideras que tienes relaciones sexuales con tu pareja para "tenerle contento" o para que esté calmado pero no porque a ti te apetezca?',
  '¿Tu pareja ha tomado alguna decisión relacionada con tu cuerpo y /o fertilidad (te ha obligado a ser madre, te ha obligado a abortar, te ha obligado a hacerte una operación estética, a tomar anticonceptivos, a no tomar anticonceptivos o a hacerte una ligadura de trompas)?',
  '¿Consideras que eres una mujer libre de tomar decisiones respecto a tu cuerpo y a su fertilidad sin coacciones, chantajes, amenazas o represalias?',
  '¿Tu pareja te ha coaccionado para tener relaciones sexuales sin protección bajo el pretexto de que "no siente lo mismo" o de que "no le quieres lo suficiente" que "no pasa nada" o cualquier otra excusa?',
  'En el caso de que hayas estado embarazada, ¿tu pareja ha intentado que abortes mediante coacciones o amenazas?',
  'En el caso de que hayas estado embarazada, ¿tu pareja se ha implicado y te ha apoyado en las visitas ginecológicas, los controles, las incidencias médicas?',
  'En el caso de que hayas tenido un aborto natural o Interrupción Voluntaria del Embarazo, ¿te has sentido cuidada, apoyada y/o respetada en tu recuperación física y emocional por tu pareja?',
  'En el caso de haber estado enferma, ¿consideras que tu pareja te ha cuidado, apoyado o estado pendiente de ti?',
  '¿Te amenaza tu pareja con "dejarte sin dinero" o "quitarte a tus hijos/hijas" (si los hubiere) o hacerte daño o matarte en el caso de que le propongas una separación o divorcio?',
  '¿Alguna vez has pensado en llamar a la policía, poner una denuncia por malos tratos o una orden de alejamiento contra tu pareja, aunque luego no lo hayas hecho?',
  '¿Alguna vez has interpuesto una denuncia por malos tratos o una orden de alejamiento contra tu pareja?',
  '¿Sientes que tienes a alguien de tu familia, amistades o tu entorno cercano con el que puedas hablar  de tu relación de pareja en el caso de necesitar ayuda o necesitar salir de esa relación?',
  '¿Conoces qué recursos tienes en tu municipio, ciudad o comunidad autónoma para poder pedir ayuda o pedir asesoramiento para tomar decisiones en el futuro respecto a tu relación?',
]

const results = [
  'Este usuario aún no ha completado el test',
  'Relacion sana',
  'Relación con algunas situaciones tóxicas',
  'Peligro - violencia psicológica/control/tóxica ',
  'Peligro alto, relación agresiva, con amenazas o intimidación y probabilidad alta de llegar a la violencia física grave',
  'Relación de Violencia Grave',
]

/*
create type usertypechoices as enum ('client','interpreter','admin');

create table users(
id serial not null primary key,
uuid uuid not null unique default gen_random_uuid(),
type usertypechoices not null default 'client',
active boolean not null default false,
lastlogin timestamp,
username varchar(255) not null unique,
password varchar(255) not null,
name varchar(255) not null,
email varchar(255) not null,
created timestamp not null default now(),
updated timestamp
);
*/

//const resource = 'users'
const resourceName = 'usuario'
const resourceNamePlural = 'usuarios'
const masculine = true
const mainField = 'name'

const ListTitle = () => {
  return <span>{`${resourceNamePlural.charAt(0).toUpperCase()}${resourceNamePlural.slice(1)}`}</span>
}

const ListFilters = ({ permissions }) => (
  <Filter>
    <TextInput label={`Buscar ${masculine ? 'un' : 'una'} ${resourceName}...`} source={`${mainField}`} alwaysOn />
  </Filter>
)

const ListPagination = () => {
  return <Pagination rowsPerPageOptions={[10, 25, 50, 100]} />
}

const ListActions = () => (
  <TopToolbar>
    <DeleteButton />
  </TopToolbar>
)

export const UserAnswerList = (props) => {
  const { isLoading, permissions } = usePermissions()
  if (isLoading) {
    return <Loading />
  }
  return (
    <List
      filters={<ListFilters permissions={permissions} />}
      title={<ListTitle />}
      pagination={<ListPagination />}
      actions={<ListActions />}
      bulkActionButtons={<BulkDeleteButton undoable="false" />}
      sort={{ field: 'created', order: 'DESC' }}
      perPage={50}
    >
      <Datagrid rowClick="show">
        <TextField sortable={false} source="name" label="Nombre de usuario" textAlign="left" />
        <FunctionField label="Número de teléfono" render={(record) => <UserPhone phone={record.phone} />} />
        <FunctionField label="Resultado" render={(record) => <UserResult result={record.result} />} />
        <DateField
          source="created"
          label="Creado"
          textAlign="left"
          locales="es"
          showTime={true}
          options={{
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            timeZone: 'Europe/Madrid',
          }}
        />
        <DateField
          sortable={false}
          source="updated"
          label="Actualizado"
          textAlign="left"
          emptyText="Nunca"
          locales="es"
          showTime={true}
          options={{
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            timeZone: 'Europe/Madrid',
          }}
        />
      </Datagrid>
    </List>
  )
}

const ShowTitle = () => {
  const record = useRecordContext()
  return (
    <span>{`${resourceName.charAt(0).toUpperCase()}${resourceName.slice(1)} ${record ? record[mainField] : ''}`}</span>
  )
}

const UserResponses = (props) => {
  return (
    <div
      style={{
        textAlign: 'left',
        marginBottom: '40px',
        fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
        backgroundColor: '#ffe6ff',
        borderRadius: '25px',
        padding: '10px',
      }}
    >
      <ul>
        {props.answerlist.map((answer, index) => (
          <li key={index} style={{ marginBottom: '20px', maxWidth: '700px' }}>
            <p style={{ marginTop: '10px' }}>{questions[index]}</p>
            <b style={{ color: 'purple' }}>{answer}</b>
          </li>
        ))}
      </ul>
    </div>
  )
}

const UserPhone = (props) => {
  return (
    <div
      style={{
        textAlign: 'left',
        fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
        marginTop: '-10px',
        marginBottom: '-10px',
      }}
    >
      <p>{props.phone.substring(2)}</p>
    </div>
  )
}

const UserResult = (props) => {
  let color = ''
  if (props.result === 1) {
    color = 'green'
  } else if (props.result === 2) {
    color = 'orange'
  } else if (props.result !== 0) {
    color = 'red'
  }
  return <b style={{ fontFamily: '"Roboto","Helvetica","Arial",sans-serif', color: color }}>{results[props.result]}</b>
}

const UserProfile = (props) => {
  return (
    <div
      style={{
        textAlign: 'left',
        fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
        backgroundColor: '#ffe6ff',
        borderRadius: '25px',
        padding: '10px',
      }}
    >
      <ul>
        <li style={{ marginBottom: '20px' }}>
          Edad: <b style={{ color: 'purple' }}>{props.prevanswerlist[0]}</b>
        </li>
        <li style={{ marginBottom: '20px' }}>
          Lugar de nacimiento: <b style={{ color: 'purple' }}>{props.prevanswerlist[1]}</b>
        </li>
        <li style={{ marginBottom: '20px' }}>
          Lugar de residencia: <b style={{ color: 'purple' }}>{props.prevanswerlist[2]}</b>
        </li>
        <li style={{ marginBottom: '20px' }}>
          Estudios: <b style={{ color: 'purple' }}>{props.prevanswerlist[3]}</b>
        </li>
        <li style={{ marginBottom: '20px' }}>
          Situación laboral: <b style={{ color: 'purple' }}>{props.prevanswerlist[4]}</b>
        </li>
        <li style={{ marginBottom: '20px' }}>
          Tiempo de relación: <b style={{ color: 'purple' }}>{props.prevanswerlist[5]}</b>
        </li>
        <li style={{ marginBottom: '20px' }}>
          Convive con su pareja: <b style={{ color: 'purple' }}>{props.prevanswerlist[6]}</b>
        </li>
        <li style={{ marginBottom: '20px' }}>
          Hijos de una relación anterior: <b style={{ color: 'purple' }}>{props.prevanswerlist[7]}</b>
        </li>
        <li style={{ marginBottom: '20px' }}>
          Hijos en la relación actual: <b style={{ color: 'purple' }}>{props.prevanswerlist[8]}</b>
        </li>
      </ul>
    </div>
  )
}

export const UserAnswerShow = (props) => {
  return (
    <Show title={<ShowTitle />} actions={<p></p>}>
      <SimpleShowLayout>
        <TextField source="name" label="Usuario" textAlign="left" />
        <FunctionField label="Número de teléfono" render={(record) => <UserPhone phone={record.phone} />} />
        <FunctionField label="Resultado" render={(record) => <UserResult result={record.result} />} />
        <TextField source="points" label="Puntos obtenidos" textAlign="left" />
        <FunctionField
          label="Ficha de usuario"
          render={(record) => <UserProfile prevanswerlist={record.prevanswerlist} />}
        />
        <FunctionField label="Test" render={(record) => <UserResponses answerlist={record.answerlist} />} />
      </SimpleShowLayout>
    </Show>
  )
}

/*const EditTitle = (props) => {
  const record = useRecordContext()
  return (
    <span>
      Editar {`${resourceName.charAt(0).toUpperCase()}${resourceName.slice(1)} ${record ? record[mainField] : ''}`}
    </span>
  )
}

export const UserEdit = () => {
  const { isLoading, permissions } = usePermissions()
  //console.log(classes)
  if (isLoading) {
    return <Loading />
  }
  return (
    <Edit title={<EditTitle />} actions={null}>
      <SimpleForm>
        <ContainerBox style={{ maxWidth: '90vw' }}>
          <LeftBox style={{ maxWidth: '45vw' }}>
            <MarginLabeledLeft label="Tipo">
              <SelectField
                source="type"
                choices={[
                  { id: 'CLIENT', name: 'Cliente' },
                  { id: 'CONSULTOR', name: 'Consultor' },
                  { id: 'MANAGER', name: 'Gestor' },
                  { id: 'ADMIN', name: 'Administrador' },
                ]}
              />
            </MarginLabeledLeft>
          </LeftBox>
          <RightBox style={{ maxWidth: '45vw' }}>
            <BooleanInput
              source="active"
              label="Activo"
              textAlign="left"
              helperText="Si no está activo no podrá iniciar sesión"
            />
            <MarginLabeledRight label="Creado">
              <DateField
                source="created"
                textAlign="left"
                locales="es"
                showTime={false}
                options={{
                  year: 'numeric',
                  month: 'numeric',
                  day: 'numeric',
                  hour: 'numeric',
                  minute: 'numeric',
                  timeZone: 'Europe/Madrid',
                }}
                sx={{ display: 'inline-flex', marginInlineEnd: '2rem', float: 'right' }}
              />
            </MarginLabeledRight>
            <MarginLabeledRight label="Actualizado">
              <DateField
                source="updated"
                emptyText="Nunca"
                textAlign="left"
                locales="es"
                showTime={true}
                options={{
                  year: 'numeric',
                  month: 'numeric',
                  day: 'numeric',
                  hour: 'numeric',
                  minute: 'numeric',
                  timeZone: 'Europe/Madrid',
                }}
                sx={{ display: 'inline-flex', marginInlineEnd: '2rem', float: 'right' }}
              />
            </MarginLabeledRight>
            <MarginLabeledRight label="Último login">
              <DateField
                source="lastlogin"
                emptyText="Nunca"
                textAlign="left"
                locales="es"
                showTime={true}
                options={{
                  year: 'numeric',
                  month: 'numeric',
                  day: 'numeric',
                  hour: 'numeric',
                  minute: 'numeric',
                  timeZone: 'Europe/Madrid',
                }}
                sx={{ display: 'inline-flex', marginInlineEnd: '2rem', float: 'right' }}
              />
            </MarginLabeledRight>
          </RightBox>
        </ContainerBox>
        <ContainerBox>
          <LeftBox>
            <TextInput
              source="username"
              label="Usuario (no se puede editar una vez creado)"
              textAlign="left"
              fullWidth={true}
              disabled
            />
          </LeftBox>
          <RightBox>
            <PasswordInput
              source="password"
              label="Contraseña"
              textAlign="left"
              fullWidth={true}
              inputProps={{ autoComplete: 'current-password' }}
              initiallyVisible={false}
              helperText="La contraseña se encriptará una vez cambiada"
            />
          </RightBox>
        </ContainerBox>
        <TextInput source="name" label="Nombre" textAlign="left" fullWidth={true} />
        <TextInput source="phone" label="Teléfono" textAlign="left" fullWidth={true} />
        <TextInput source="email" label="Email" textAlign="left" fullWidth={true} />
      </SimpleForm>
    </Edit>
  )
}

const CreateTitle = () => {
  return (
    <span>
      Crear {masculine ? 'nuevo' : 'nueva'} {`${resourceName.charAt(0).toUpperCase()}${resourceName.slice(1)}`}
    </span>
  )
}

// source: https://stackoverflow.com/questions/9628879/javascript-regex-username-validation
const isUsernameValid = (username) => {
  
  const res = /^[ña-zA-Z0-9_]+$/.exec(username)
  const valid = !!res
  return valid
}

const validateUserCreation = (values) => {
  //console.log(values)
  const errors = {}
  if (!values.type) {
    errors.type = 'Es necesario indicar el tipo de usuario'
  }
  if (values.active === undefined) {
    errors.active = 'Es necesario indicar si el usuario está activo o no'
  }
  if (!values.username) {
    errors.username = 'Es necesario introducir un nombre de usuario'
  } else if (!isUsernameValid(values.username)) {
    errors.username =
      'El nombre de usuario solamente puede tener letras mayúsculas, minúsculas, números y barras bajas (_), no puede contener acentos y espacios (entre otras cosas).'
  }
  if (!strongRegex.test(values.password)) {
    errors.password = 'La contraseña no parece cumplir los requisitos mínimos'
  }
  if (!values.name) {
    errors.name = 'Es necesario introducir un nombre'
  }
  if (!values.email) {
    errors.email = 'Es necesario introducir un email'
  }
  return errors
}

// password regexes
//const strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\\$%\\^&\\*])(?=.{8,})");
const strongRegex = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})')
// the password parts
const lowercaseRegex = new RegExp('^(?=.*[a-z])')
const uppercaseRegex = new RegExp('^(?=.*[A-Z])')
const numericRegex = new RegExp('^(?=.*[0-9])')
const specialRegex = new RegExp('^(?=.*[!@#$%^&*])')
const lengthRegex = new RegExp('^(?=.{8,})')

export const UserCreate = (props) => {
  const [lowercase, setLowercase] = useState(false)
  const [uppercase, setUppercase] = useState(false)
  const [numeric, setNumeric] = useState(false)
  const [special, setSpecial] = useState(false)
  const [length, setLength] = useState(false)
  // load perms
  const { isLoading, permissions } = usePermissions()
  if (isLoading) {
    return <Loading />
  }
  const checkPassword = (event) => {
    // source: https://www.thepolyglotdeveloper.com/2015/05/use-regex-to-test-password-strength-in-javascript/
    console.log(event.target.value)
    setLowercase(lowercaseRegex.test(event.target.value))
    setUppercase(uppercaseRegex.test(event.target.value))
    setNumeric(numericRegex.test(event.target.value))
    setSpecial(specialRegex.test(event.target.value))
    setLength(lengthRegex.test(event.target.value))
  }
  return (
    <Create title={<CreateTitle />}>
      <SimpleForm validate={validateUserCreation}>
        {
          //Only admins can create other admins
          ['ADMIN'].includes(permissions) ? (
            <SelectInput
              source="type"
              label="Tipo"
              helperText="Tipo de usuario (nota: no se puede cambiar una vez creado)"
              fullWidth={true}
              choices={[
                { id: 'CLIENT', name: 'Cliente' },
                { id: 'CONSULTOR', name: 'Consultor' },
                { id: 'MANAGER', name: 'Gestor' },
                { id: 'ADMIN', name: 'Administrador' },
              ]}
            />
          ) : ['MANAGER'].includes(permissions) ? (
            <SelectInput
              source="type"
              label="Tipo"
              helperText="Tipo de usuario (nota: no se puede cambiar una vez creado)"
              fullWidth={true}
              choices={[
                { id: 'CLIENT', name: 'Cliente' },
                { id: 'CONSULTOR', name: 'Consultor' },
              ]}
            />
          ) : (
            <SelectInput
              source="type"
              label="Tipo"
              helperText="Tipo de usuario (nota: no se puede cambiar una vez creado)"
              fullWidth={true}
              choices={[{ id: 'CLIENT', name: 'Cliente' }]}
            />
          )
        }
        <BooleanInput
          source="active"
          initialValue={false}
          label="Activo"
          textAlign="left"
          helperText="Si no está activo no podrá iniciar sesión"
          fullWidth={true}
        />
        <TextInput
          source="username"
          label="Usuario (no se puede editar una vez creado, tiene que ser único)"
          textAlign="left"
          helperText="Tiene que ser único"
          fullWidth={true}
        />
        <PasswordInput
          source="password"
          label="Contraseña"
          textAlign="left"
          fullWidth={true}
          inputProps={{ autoComplete: 'current-password' }}
          helperText="La contraseña se encriptará una vez introducida"
          onChange={checkPassword}
        />
        <Box style={{ paddingBottom: '8px' }}>
          <Typography variant="caption" gutterBottom style={{ marginLeft: '14px' }}>
            Make sure that the password meets the following criteria and contains:
            <ul>
              <li style={{ color: lowercase ? 'green' : 'red' }}>at least 1 lowercase alphabetical character</li>
              <li style={{ color: uppercase ? 'green' : 'red' }}>at least 1 uppercase alphabetical character</li>
              <li style={{ color: numeric ? 'green' : 'red' }}>at least 1 numeric character</li>
              <li style={{ color: special ? 'green' : 'red' }}>at least one special character</li>
              <li style={{ color: length ? 'green' : 'red' }}>eight characters or longer</li>
            </ul>
          </Typography>
        </Box>
        <TextInput source="name" label="Nombre" textAlign="left" fullWidth={true} />
        <TextInput source="phone" label="Teléfono" textAlign="left" fullWidth={true} />
        <TextInput source="email" label="Email" textAlign="left" fullWidth={true} />
      </SimpleForm>
    </Create>
  )
}*/
